<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-checkbox
      :checked="enxague_e_secagem"
      @input="v => $emit('updateModel', { enxague_e_secagem: v })"
      :state="validateState('enxague_e_secagem')"
      v-model="enxague_e_secagem"
      name="enxague_e_secagem"
    >
      Houve enxágue e secagem?
    </b-form-checkbox>
  </b-col>
</template>

<script>
export default {
  name: 'SecagemEnxague',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },
  data() {
    return {
      enxague_e_secagem: false,
    };
  },
  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
